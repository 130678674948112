import React from 'react'
import Graph from 'react-graph-vis'

const colors = ['#2d76db', '#da4453', '#7a59e7']

const graphRaw = {
  nodes: [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    { id: 99, label: 'Learner', color: '#27cb0c', font: { size: 24 } }
  ],
  edges: [
    { from: 1, to: 2, width: 3 },
    { from: 1, to: 3, width: 5 },
    { from: 1, to: 9, width: 5 },
    { from: 2, to: 4, width: 3 },
    { from: 2, to: 4, width: 2 },
    { from: 2, to: 5, width: 3 },
    { from: 2, to: 9, width: 3 },
    { from: 3, to: 4, width: 1 },
    { from: 3, to: 6, width: 4 },
    { from: 4, to: 7, width: 4 },
    { from: 6, to: 7, width: 2 },
    { from: 7, to: 8, width: 5 },
    { from: 9, to: 4, width: 3 },
    { from: 9, to: 11, width: 3 },
    { from: 10, to: 11, width: 3 },
    { from: 10, to: 12, width: 3 },
    { from: 11, to: 12, width: 3 },
    { from: 12, to: 13, width: 3 },
    { from: 12, to: 14, width: 3 },
    { from: 13, to: 15, width: 3 },
    { from: 13, to: 16, width: 3 },
    { from: 14, to: 15, width: 3 },
    { from: 15, to: 16, width: 3 },
    { from: 15, to: 17, width: 3 },
    { from: 16, to: 18, width: 3 },
    { from: 17, to: 19, width: 3 },
    { from: 18, to: 19, width: 3 },
    { from: 99, to: 1, width: 3 },
    { from: 99, to: 2, width: 3 },
    { from: 99, to: 3, width: 3 },
    { from: 99, to: 9, width: 3 },
    { from: 99, to: 10, width: 3 }
  ]
}

const options = {
  layout: {
    hierarchical: false
  },
  edges: {
    color: '#000000'
  },
  physics: { enabled: false }
}

const graph = {
  nodes: graphRaw.nodes.map((node, i) => ({
    ...node,
    id: node.id || i + 1,
    borderWidth: 0,
    borderWidthSelected: 0,
    color: node.color
      ? node.color
      : i < 8
        ? colors[0]
        : i > 10
          ? colors[1]
          : colors[2],
    font: {
      color: '#fff',
      ...node.font
    },
    label: node.label || `Content ${i + 1}`,
    shape: 'circle'
  })),
  edges: graphRaw.edges.map(edge => ({ ...edge, arrows: '' }))
}

export default function App () {
  return (
    <Graph
      graph={graph}
      options={options}
      style={{ height: 'calc(100vh - 1rem)', width: 'calc(100vw-1rem)' }}
    />
  )
}
